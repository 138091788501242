// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Defines the different device's sizes
$grid-breakpoints: (
  xs: 0,
  // Phone (0px - 575px)
  sm: 576px,
  // Phablet (576px - 767px)
  md: 768px,
  // Tablet vertical (768px - 991px)
  lg: 992px,
  // Tablet horizontal, Desktop (992px and above)
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

/** CSS Variables for all modes **/
:root {
  --secondary-button-background: #d2d8ee;
  --secondary-button-foreground: #313c5a;

  // This tool was used to generate the following variable colors: https://ionicframework.com/docs/theming/color-generator
  /*
  */

  --ion-color-primary: #2d4372;
  --ion-color-primary-rgb: 45, 67, 114;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #283b64;
  --ion-color-primary-tint: #425680;

  --ion-color-secondary: #1c62b7;
  --ion-color-secondary-rgb: 28, 98, 183;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1956a1;
  --ion-color-secondary-tint: #3372be;

  /*
  --ion-color-primary: #3d558e;
  --ion-color-primary-rgb: 61, 85, 142;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #364b7d;
  --ion-color-primary-tint: #506699;
  */

  /*
  --ion-color-secondary: #327ac8;
  --ion-color-secondary-rgb: 50, 122, 200;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2c6bb0;
  --ion-color-secondary-tint: #4787ce;
*/
  --ion-color-tertiary: #cfe6fe;
  --ion-color-tertiary-rgb: 207, 230, 254;
  --ion-color-tertiary-contrast: #1b1b1b;
  --ion-color-tertiary-contrast-rgb: 27, 27, 27;
  --ion-color-tertiary-shade: #b6cae0;
  --ion-color-tertiary-tint: #d4e9fe;

  --ion-color-success: #23b323;
  --ion-color-success-rgb: 35, 179, 35;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #1f9e1f;
  --ion-color-success-tint: #39bb39;

  --ion-color-warning: #ffd439;
  --ion-color-warning-rgb: 255, 212, 57;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb32;
  --ion-color-warning-tint: #ffd84d;

  --ion-color-danger: #e8152d;
  --ion-color-danger-rgb: 232, 21, 45;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cc1228;
  --ion-color-danger-tint: #ea2c42;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #959eaf;
  --ion-color-medium-rgb: 149, 158, 175;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #838b9a;
  --ion-color-medium-tint: #a0a8b7;

  --ion-color-light: #fff;
  --ion-color-light-rgb: 245, 246, 250;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8dc;
  --ion-color-light-tint: #f6f7fb;

  // --- CUSTOM COLORS ---
  // The color for CRITICAL alarms
  --ion-color-critical-alarm: #ff2f51;
  --ion-color-critical-alarm-rgb: 255, 47, 81;
  --ion-color-critical-alarm-contrast: #ffffff;
  --ion-color-critical-alarm-contrast-rgb: 255, 255, 255;
  --ion-color-critical-alarm-shade: #e02947;
  --ion-color-critical-alarm-tint: #ff4462;

  .ion-color-critical-alarm {
    --ion-color-base: var(--ion-color-critical-alarm);
    --ion-color-base-rgb: var(--ion-color-critical-alarm-rgb);
    --ion-color-contrast: var(--ion-color-critical-alarm-contrast);
    --ion-color-contrast-rgb: var(--ion-color-critical-alarm-contrast-rgb);
    --ion-color-shade: var(--ion-color-critical-alarm-shade);
    --ion-color-tint: var(--ion-color-critical-alarm-tint);
  }

  // The color for MAJOR alarms
  --ion-color-major-alarm: #fb9b44;
  --ion-color-major-alarm-rgb: 251, 155, 68;
  --ion-color-major-alarm-contrast: #000000;
  --ion-color-major-alarm-contrast-rgb: 0, 0, 0;
  --ion-color-major-alarm-shade: #dd883c;
  --ion-color-major-alarm-tint: #fba557;

  .ion-color-major-alarm {
    --ion-color-base: var(--ion-color-major-alarm);
    --ion-color-base-rgb: var(--ion-color-major-alarm-rgb);
    --ion-color-contrast: var(--ion-color-major-alarm-contrast);
    --ion-color-contrast-rgb: var(--ion-color-major-alarm-contrast-rgb);
    --ion-color-shade: var(--ion-color-major-alarm-shade);
    --ion-color-tint: var(--ion-color-major-alarm-tint);
  }

  // The color for MINOR alarms
  --ion-color-minor-alarm: #ffd941;
  --ion-color-minor-alarm-rgb: 255, 217, 65;
  --ion-color-minor-alarm-contrast: #000000;
  --ion-color-minor-alarm-contrast-rgb: 0, 0, 0;
  --ion-color-minor-alarm-shade: #e0bf39;
  --ion-color-minor-alarm-tint: #ffdd54;

  .ion-color-minor-alarm {
    --ion-color-base: var(--ion-color-minor-alarm);
    --ion-color-base-rgb: var(--ion-color-minor-alarm-rgb);
    --ion-color-contrast: var(--ion-color-minor-alarm-contrast);
    --ion-color-contrast-rgb: var(--ion-color-minor-alarm-contrast-rgb);
    --ion-color-shade: var(--ion-color-minor-alarm-shade);
    --ion-color-tint: var(--ion-color-minor-alarm-tint);
  }

  // The color for WARNING alarms
  --ion-color-warning-alarm: #b42eab;
  --ion-color-warning-alarm-rgb: 180, 46, 171;
  --ion-color-warning-alarm-contrast: #ffffff;
  --ion-color-warning-alarm-contrast-rgb: 255, 255, 255;
  --ion-color-warning-alarm-shade: #9e2896;
  --ion-color-warning-alarm-tint: #bc43b3;

  .ion-color-warning-alarm {
    --ion-color-base: var(--ion-color-warning-alarm);
    --ion-color-base-rgb: var(--ion-color-warning-alarm-rgb);
    --ion-color-contrast: var(--ion-color-warning-alarm-contrast);
    --ion-color-contrast-rgb: var(--ion-color-warning-alarm-contrast-rgb);
    --ion-color-shade: var(--ion-color-warning-alarm-shade);
    --ion-color-tint: var(--ion-color-warning-alarm-tint);
  }

  // The colors for secondary buttons
  .ion-color-secondary-buttons {
    --ion-color-base: var(--secondary-button-background);
    --ion-color-base-rgb: 210, 216, 238;
    --ion-color-base-contrast: var(--secondary-button-foreground);
    --ion-color-base-contrast-rgb: 49, 60, 90;
    --ion-color-base-shade: #b9bed1;
    --ion-color-base-tint: #d7dcf0;
  }

  // The colors for selected elements
  .ion-color-selected-element {
    --ion-color-base: var(--secondary-button-background);
    --ion-color-base-rgb: 210, 216, 238;
    --ion-color-base-contrast: var(--secondary-button-foreground);
    --ion-color-base-contrast-rgb: 49, 60, 90;
    --ion-color-base-shade: #b9bed1;
    --ion-color-base-tint: #d7dcf0;
  }

  // The colors for selected tabs
  .ion-color-selected-tab {
    --ion-color-base: #cde0ff;
    --ion-color-base-rgb: 205, 224, 255;
    --ion-color-base-contrast: #000000;
    --ion-color-base-contrast-rgb: 0, 0, 0;
    --ion-color-base-shade: #b4c5e0;
    --ion-color-base-tint: #d2e3ff;
  }

  // The border used for the main container
  --container-border: #929292;

  // The color shown in a disabled area
  --container-disabled-background: #dfdfdf;

  --font-semi-bold: 550;

  --foreground-soft-color: #838486;

  // The default background color
  --ion-background-color: var(--ion-color-light);
  --selected-element-background: #cce1fc;
  --selected-element-foreground: #38629e;

  // The border used by the Application in internal panels
  --panel-border: #e1e1e1;
  --side-menu-backgroud: #f9f9f9;
}
