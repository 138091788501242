//******************************************************
// Defines the basic styles for each element of the app
//******************************************************

html {
    font-family: "Segoe WP Regular";
}

h5 {
    color: #555;
    margin-top: 10px;
    margin-bottom: 5px;
    clear: both;
}

.alert-wrapper {
    background-color: #fff;
    border-radius: 0px;

    .alert-warning {
        font-size: 20px;
        color: #dd5d63;
    }

    .alert-button {
        background: var(--ion-color-primary);
        border-radius: 0px;
        color: var(--ion-color-primary-contrast);
        font-weight: 400;
        font-size: 14px;
        height: 38px;
        margin: 2px;
        text-align: center;
        width: 100px;
    }

    .alert-button-group {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
    }

    .alert-button-inner {
        justify-content: center;
    }

    .alert-message {
        font-size: small;
    }

    .alert-radio-icon {
        height: 18px;
        left: 40px;
        width: 18px;
    }

    .alert-radio-inner {
        left: 3px;
        top: 2px;
        height: 9px;
        width: 9px;
    }

    .alert-radio-label {
        font-size: small;
    }

    .alert-title {
        font-size: medium;
        font-weight: bold;
        text-transform: none;
    }
}

.alert-global {
    .alert-wrapper {
        border-radius: 0px;
        max-width: 400px;
    }

    .alert-button-group-vertical.sc-ion-alert-md {
        flex-direction: row !important;
    }
}

.button {
    border-radius: 4px;
    margin-top: 15px;
    background-color: #3c4975 !important;
    color: #fff !important;
}

.button-secondary {
    --background: var(--secondary-button-background);
    color: var(--secondary-button-foreground);
}

.item {
    // Removes borders
    --border-width: 0 0 0 0;
    --background: var(--side-menu-backgroud);
}

.item-interactive {
    // Removes borders
    --border-width: 0 0 0 0;
}

.loading-global {
    .loading-content {
        font-size: small;
    }

    .loading-wrapper {
        background-color: #fff;
        border-radius: 0px;
    }
}

.mat-menu-panel {
    min-height: 25px !important;

    .mat-menu-item {
        height: 25px;
        line-height: 25px;
    }
}

.select-global {
    .alert-wrapper {
        border-radius: 0px;
    }
}

// Location and size of modal panels
.show-modal {
    .modal-wrapper {
        position: absolute;
        bottom: 0;
        min-height: initial;
        top: initial;
        height: 75%;
    }
}

.tab-has-label-only {
    font-weight: bold;
}

.tab-selected {
    background-color: var(--selected-element-background);
    color: var(--selected-element-foreground);
    border-bottom: 2px solid #0650a5;
}

input.ng-invalid {
    border: 1px solid #ffc3c5 !important;
    background-color: #faf5f5 !important;

    &:focus {
        outline: none !important;
        border: 3px solid #ffc3c5 !important;
        box-shadow: none;
    }
}

input.invalid {
    border: 1px solid #ffc3c5 !important;
    background-color: #faf5f5 !important;

    &:focus {
        outline: none !important;
        border: 3px solid #ffc3c5 !important;
        box-shadow: none;
    }
}

/** Page styles */

.page {
    background: white;
    font-size: 12px;
    // Override ionic
    --background: white;
    --font-size: 12px;
}

.page-menu {
    float: left;
    width: 100%;
    padding: 0 15px;
    min-height: 50px;
    border-bottom: 1px solid #ccc;
    display: table-row;
}

.separator-menu {
    float: left;
    width: 1px;
    height: 50px;
    background-color: #ccc;
    margin-top: 12px;
}

.page-content {
    float: left;
    width: 100%;
    overflow: auto;
}

.page-content-mobile {
    height: 100%;
}

.group-span-input-form {
    float: left;
    display: flex;
    margin-bottom: 3px;
    padding-right: 5px;

    span {
        width: 100px;
        min-width: 100px;
        padding: 4px 0;
    }

    input[type="text"],
    input[type="number"],
    select {
        width: 100%;
        background: #f5f6fa;
        border: 1px solid #f5f6fa;
        border-radius: 3px;
        padding: 3px;

        &:disabled {
            background: white !important;
        }
    }

    input[type="date"],
    input[type="datetime"],
    input[type="time"] {
        background: #f5f6fa;
        border: 1px solid #f5f6fa;
        border-radius: 3px;
        padding: 3px;

        &:disabled {
            background: white !important;
        }
    }

    input[type="checkbox"] {
        margin: 2px 0;
    }

    label {
        padding: 5px 0;
    }
}

.sub-title {
    color: #555;
    font-size: 14px;
    font-weight: 600;
    float: left;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.flex {
    display: flex;
}

/** Ionics Elements */
ion-badge {
    border-radius: 10px;
}

ion-button {
    --border-radius: 0px;
    --box-shadow: none;
}

ion-col {
    background: #fff;
    border: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

ion-content {
    display: flex;
    vertical-align: middle;
}

ion-header .toolbar {
    margin: auto;
}

ion-grid {
    background: var(--container-disabled-background);
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    width: 100%;
}

ion-item {
    font-size: 11pt;
    --inner-border-width: 0px;
    border-bottom: 1px solid var(--panel-border);

    // Style of the badge element inside the side menu
    ion-badge {
        padding: 5px 8px 5px 8px;
    }
}

ion-row {
    align-items: center;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    vertical-align: center;
}

ion-tab-button {
    --ripple-color: var(--ion-color-primary);

    // Style of the badge element inside the tabs bar
    ion-badge {
        padding: 4px 5px 3px 5px;
        top: 4px;
    }
}

ion-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

ion-buttons {
    .button {
        margin-top: 0 !important;
        background-color: none;
    }
}

/** LOADING */
.lds-roller {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #cef;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.notes {
    float: left;
    font-family: "Segoe WP Regular";
    font-size: 14px;
    font-weight: bold;
    color: #507ba1;
}

.warning {
    float: left;
    font-family: "Segoe WP Regular";
    font-size: 13px;
    font-weight: bold;
    color: #dd5d63;
}

/** SCROLLBARS */
/* Customize website's scrollbar like Mac OS Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
    background-color: #fff;
    width: 6px;
    height: 6px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    border: none;
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

/** For mobiles */
@media only screen and (max-width: 768px) {
    .group-span-input-form {
        display: block !important;
    }

    .page-menu {
        min-height: auto;
        max-height: 55px;
    }

    .separator-menu {
        display: none !important;
    }
}